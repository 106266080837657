import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import useIsInViewport from 'shared/hooks/useIsInViewport';
import useTranslation from 'shared/hooks/useTranslation';

import { Section, SubHeading, Heading, Header } from '../Styled';

const StyledHeader = styled(Header)`
  display: flex;
  flex-direction: column;
  padding: 4rem 2.4rem 0 2.4rem;
  margin: 0 auto;

  ${({ theme }) => theme.MEDIA.MD} {
    padding: 8rem 2.4rem 0 2.4rem;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    padding: 12rem 3.4rem 0 3.4rem;
  }
`;

const WidgetContainer = styled.div`
  margin-top: 8rem;

  .hydra-main-reviews__text {
    b {
      color: #fc8103;
    }
  }
  .tm-hydra .hydra-main-box__section {
    overflow-y: hidden;
    height: 100%;
  }
  .tm-hydra .hydra-main-box__section {
    max-height: 1380px;
  }
  .hydra-main-reviews__show-more {
    display: none !important;
  }
`;

function DisplayHydra(): JSX.Element {
  const { t, lang } = useTranslation('homePage');

  const hydraSectionRef = useRef(null);
  const isWidgetInView = useIsInViewport(hydraSectionRef, { threshold: 0 });
  const [status, setStatus] = useState(true);

  const widgetId = lang === 'pl' ? '12d3ffbc-32cc-4fac-921b-d5f4b5d2b0ca' : 'a051b1b0-d964-4df9-97da-38209f56813d';

  useEffect(() => {
    const WORDS =
      lang === 'pl'
        ? ['ziemnych terenach', 'jasna cholewka', 'długie wybiegania', 'kolana są bezpieczne', 'technice amortyzacji']
        : ['light upper', 'long runs', 'rough terrain', 'knees are safe', 'cushioning technology'];
    const REG_EX = new RegExp(WORDS.join('|'), 'gi');

    function handleColor() {
      const hydra = document.getElementById(widgetId);
      const review = hydra?.querySelector('.hydra-main-reviews__text > div');

      if (review) {
        review.innerHTML = review?.innerHTML.replace(new RegExp(REG_EX, 'ig'), (w) => `<b>${w}</b>`);
      }
      setStatus(false);
    }

    if (isWidgetInView && status) {
      handleColor();
    }

  }, [lang, isWidgetInView, status, widgetId]);

  useEffect(() => {
    const srcUrl = `https://trustmate.io/api/widget/${widgetId}/script?product=${lang === 'pl' ? 1124 : 12345}`;

    const s = document.createElement('script');
    const addScript = (src: string) => {
      s.setAttribute('src', src);
      s.setAttribute('defer', 'defer');
      document.body.append(s);
      s.remove();
    };
    addScript(srcUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
      <StyledHeader ref={hydraSectionRef}>
        <Heading>{t('widgetSection.attractiveWidgets')}</Heading>
        <SubHeading>{t('widgetSection.credibleBrand')}</SubHeading>
      </StyledHeader>
      <WidgetContainer>
        <div id={widgetId} />
      </WidgetContainer>
    </Section>
  );
}

export default DisplayHydra;
